.react-pdf__Page__annotations{
    display:none
}

.react-pdf__Document{
    width: 100%
}

.react-pdf__Page__canvas {
    margin: 0 auto;
    width: 70% !important;
    height: 100% !important;
}