.main-navbar {
    z-index: 500;
    box-shadow: 1.5px 1.5px 1.5px 1.5px rgba(0, 0, 0, 0.2);
    background-color: white;
    width: 100%;
    zoom: 90%;
}

.nav-content {
    display: flex;
    width: 100%;
}

.lwr-nav-links {
    display: flex;
    flex: 25;
}

.lwr-nav {
    flex-grow: 1;
}

.nav-bar-button-group {
    display: flex;
    flex: 3;
}

.logout-container {
    flex: 1 1 auto;
    display: flex;
    justify-content: right;
}

.info-container {
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #ccc;
}

input:focus+.slider {
    box-shadow: 0 0 1px #ccc;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}