@import '@silevis/reactgrid/styles.css';

.reactgrid {
  font-family: Arial, Helvetica, sans-serif;
  color: 'black'
}

.rg-cell rg-text-cell valid  {
  color: 'white'
}
.reactgrid-content .rg-pane .rg-cell {
  font-size: 1em;
  box-sizing: border-box;
  white-space: nowrap;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  padding: 0 4px;
  outline: none;
  touch-action: auto;
  border-color: #e8e8e8;
  color: #000000;
}

.reactgrid-content .rg-pane .rg-header-cell {
  color: white;
  background: #005E7D !important;
  font-size: large;
}

.grid-container {
  width: 100%; /* This ensures the container takes the full width of the parent */
  height: 80vh;
  overflow-y: auto;
  overflow-x: auto; /* This will add a horizontal scrollbar when needed */
}

.reactgrid-cell[data-is-header="true"] {
  background-color: #000000;  /* Set your desired color */
  color: #fff;  /* Assuming you want white text, but adjust as needed */
  /* Add other styles if needed */
}

.modal-90w {
  min-width:  90% !important;
}
