.striped-table tbody tr td:nth-child(odd) {
    border: 0.1px solid black !important;
    background-color: #005E7D !important;
    color: white !important;
    text-align: center;
}

.striped-table1 tbody td:nth-child(odd) {
    border: 0.0px solid black !important;
    background-color: #005E7D !important;
    color: white !important;
    text-align: left;
}

.striped-table tbody tr td:nth-child(even) {
    background-color: #FFFFFF !important;
}

.striped-table-vertical tbody tr:nth-child(odd) td {
    border: 1px solid black !important;
    background-color: #005E7D !important;
    color: white !important;
    text-align: center;
}

.striped-table-vertical tbody tr:nth-child(even) td {
    background-color: #FFFFFF !important;
}


.striped-table {
    border-radius: 10px !important;
}

.fullWidthDatePicker .react-datepicker-wrapper,
.fullWidthDatePicker .react-datepicker__input-container {
    width: 100%;
}