.field-nav-container {
  display: flex;
  flex-direction: row;
  margin-top: 0.8%;
  overflow-y: hidden;
}

@media screen and (max-width: 600px) {
  .field-nav-container {
    flex-direction: column;
  }
}

.timesheets-sidebar {
  flex: 0 0 230px;
  background-color: white;
}

.timesheets-content {
  flex: 1;
  overflow-y: hidden;
}

.timesheets.nav-link {
  border-radius: 10px !important;
  border: 0.1px solid grey !important;
  margin-bottom: 3%;
}