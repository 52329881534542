.header {
    font-weight: 350;
}

.header-2 {
    font-weight: 500;
}

.report {
    max-width: 1600px;
    padding: 50px 50px 50px 50px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    line-height: 24px;
}

.horizontalReport {
    display: inline-block;
    padding: 20px ;
    white-space: nowrap;
    width: 290vw;
    height: 250vh;
    margin: 0px 0px 0px 10px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    background-color: white;
}

.fw-horizontal-report{
    padding: 20px ;
    white-space: nowrap;
    height: 100%;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, .15);
    background-color: white;
}

.assessment-badge {
    background-color: #005E7D !important;
}

.bg-primary {
    --bs-bg-opacity: 1;
    background-color: #005E7D !important;
}