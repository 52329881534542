#logo {
    height: 90px;
    width: 90px;
    padding: 10px;
}

#grid {
    max-height: 100vh;
    overflow-y: hidden;
}

#sidebar {
    position: fixed;
}

#body {
    height: 100%;
    background-color: white;
}