.table-container {
  max-height: 90vh;
  /* adjust this value to your needs */
  overflow-y: auto;
  width: 100%;
}

.table-container thead th {
  position: sticky;
  top: 0;
  background: white;
  /* adjust this value to your needs */
  z-index: 1;
  border-top: 1px solid #dee2e6;
}

.table-container thead th::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 1px;
  /* or border thickness */
  background: #dee2e6;
  /* or border color */
}