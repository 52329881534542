.office-time .modal-dialog {
  max-width: none !important;
  width: 96vw !important;
}

.offcanvas {
  max-width: none !important;
  width: 55vw !important;
}

.offcanvas.create-wo {
  max-width: none !important;
  width: 90vw !important;
}